h1 {
    color: white;
}

.modal_main {
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(7px);

    .modal-dialog {
        .modal-content {
            max-width: 418px;
            border-radius: 10px;
            background: #F3F3F3;

            .btn-close {
                background-image: url(../../assets/btn-close.png);
                width: 30px;
                height: 30px;
                position: absolute;
                right: 25px;
                flex-shrink: 0;
                padding: 0;
                opacity: unset;
                background-size: contain;
                outline: unset;
                box-shadow: unset;
            }

            .modal-body {
                padding: 25px;

                .modal1 {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .imgqus {
                        width: 70px;
                        height: 70px;
                        padding-top: 5px;
                    }

                    h1 {
                        color: #013159;
                        text-align: center;
                        font-family: 'gsemibold';
                        font-size: 22px;
                        font-style: normal;
                        line-height: 120%;
                        /* 26.4px */
                        letter-spacing: -0.44px;
                        padding-top: 30px;
                    }

                    p {
                        color: #627F97;
                        text-align: center;
                        font-family: 'gregular';
                        font-size: 14px;
                        font-style: normal;
                        line-height: 120%;
                        /* 16.8px */
                        letter-spacing: -0.28px;
                        letter-spacing: -0.32px;
                        padding-top: 15px;
                        padding-bottom: 5px;
                    }

                }

                .modal2 {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .imgqus {
                        width: 70px;
                        height: 70px;
                    }

                    .codediv {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        gap: 3px;
                        padding: 20px 0px;
                        border-radius: 9px;
                        background: #F9F9F9;
                        text-align: center;
                        align-items: center;
                        width: 100%;
                    }

                    h1 {
                        color: #013159;
                        text-align: center;
                        font-family: 'gmedium';
                        font-size: 22px;
                        font-style: normal;
                        line-height: 120%;
                        /* 26.4px */
                        letter-spacing: -0.44px;
                        padding-top: 30px;
                        padding-bottom: 15px;
                    }

                    p {
                        color: #627F97;
                        text-align: center;
                        font-family: 'gmedium';
                        font-size: 14px;
                        font-style: normal;
                        line-height: 100%;
                        /* 14px */
                    }

                    h2 {
                        color: #013159;
                        font-family: 'gmedium';
                        font-size: 14px;
                        font-style: normal;
                        line-height: 100%;
                    }

                    h3 {
                        color: #627F97;
                        text-align: center;
                        font-size: 14px;
                        font-style: normal;
                        font-family: 'gregular';
                        line-height: 120%;
                        /* 16.8px */
                        letter-spacing: -0.28px;
                        padding-top: 15px;
                        padding-bottom: 5px;
                    }
                }

                .modal3 {
                    .form-control {
                        border-radius: 15px;
                        border: 1px solid rgba(0, 0, 0, 0.06);
                        background: rgba(240, 243, 247, 0.05);
                        color: #013159;
                        padding: 18px;
                        outline: unset;
                        box-shadow: unset;
                        margin-bottom: 5px;
                    }

                    ::placeholder {
                        color: #8FA3B4;
                        font-family: 'gregular';
                        font-size: 16px;
                        font-style: normal;
                        line-height: 100%;
                        /* 16px */
                        letter-spacing: -0.32px;
                    }

                    h1 {
                        color: #013159;
                        font-family: 'gregular';
                        font-size: 16px;
                        font-style: normal;
                        line-height: 170%;
                        /* 27.2px */
                        letter-spacing: -0.32px;
                        padding-top: 5px;
                        padding-bottom: 10px;
                    }

                    .modal3_inner {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        padding: 25px 28px;
                        border-radius: 16px;
                        border: 1px dashed #E0E5E9;
                        background: rgba(240, 243, 247, 0.05);
                        margin-bottom: 18px;



                        h2 {
                            color: #013159;
                            text-align: center;
                            font-family: 'gmedium';
                            font-size: 16px;
                            font-style: normal;
                            line-height: 100%;
                            /* 16px */
                            letter-spacing: -0.32px;
                        }

                        p {
                            color: #627F97;
                            font-size: 14px;
                            font-style: normal;
                            font-family: 'gregular';
                            line-height: 100%;
                            letter-spacing: -0.28px;
                            padding-top: 15px;
                            padding-bottom: 20px;
                        }

                        .ChooseFile {
                            color: #013159;
                            text-align: center;
                            font-family: 'gmedium';
                            font-size: 12px;
                            font-style: normal;
                            line-height: 150%;
                            /* 18px */
                            letter-spacing: -0.24px;
                            text-transform: capitalize;
                            height: 40px;
                            padding: 11px 18px;
                            border-radius: 120px;
                            background: #E6E9EB;
                            border: unset;
                        }
                    }
                }

                .modal4 {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .imgqus {
                        width: 70px;
                        height: 70px;
                    }

                    h1 {
                        color: #013159;
                        text-align: center;
                        font-family: 'gmedium';
                        font-size: 22px;
                        font-style: normal;
                        line-height: 120%;
                        /* 26.4px */
                        letter-spacing: -0.44px;
                        padding-top: 30px;
                        padding-bottom: 15px;
                    }

                    p {
                        color: #627F97;
                        text-align: center;
                        font-family: 'gregular';
                        font-size: 14px;
                        font-style: normal;
                        line-height: 120%;
                        /* 16.8px */
                        padding-bottom: 5px;
                        letter-spacing: -0.28px;
                    }

                    h2 {
                        color: #FFF;
                        text-align: center;

                        padding: 20px;
                        border-radius: 10px;
                        background: rgba(240, 243, 247, 0.05);
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 170%;
                        /* 37.4px */
                        letter-spacing: -0.44px;
                    }

                    h3 {
                        color: #FFF;
                        text-align: center;

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 170%;
                        /* 27.2px */
                        letter-spacing: -0.32px;
                        padding-top: 25px;
                    }
                }
            }

            .modal-header {
                position: relative;
                padding: 25px;
                padding-bottom: 0px;
                color: #013159;
                font-family: 'gmedium';
                font-size: 18px;
                font-style: normal;
                line-height: 120%;
                /* 21.6px */
                border-bottom: unset;
            }

            .modal-footer {

                padding: 25px;
                padding-top: unset;

                .modal_footer {
                    display: flex;
                    margin: 0px !important;
                    align-items: center;
                    gap: 20px;
                    width: 100%;
                }

                border-top: unset;

                .cancelbutton {
                    color: #013159;
                    font-family: 'gmedium';
                    font-size: 14px;
                    font-style: normal;
                    line-height: 100%;
                    /* 14px */
                    width: 100%;
                    /* 24px */
                    background-color: unset;
                    letter-spacing: -0.32px;
                    border-radius: 100px;
                    background: #DBE0E4;
                    border: unset;
                    padding: 14px 25px;
                }

                .surebutton {
                    color: #F9F9F9;
                    font-family: 'gmedium';
                    font-size: 14px;
                    font-style: normal;
                    line-height: 100%;
                    width: 100%;
                    letter-spacing: -0.32px;
                    padding: 14px 25px;
                    border: unset;
                    border-radius: 100px;
                    background: #013159;
                }
            }
        }
    }
}


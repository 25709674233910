@font-face {
  font-family: 'gregular';
  src: url('../src/fonts/Galxe-Lader-Regular.ttf');
}

@font-face {
  font-family: 'gmedium';
  src: url('../src/fonts/Galxe-Lader-Medium.ttf');
}

@font-face {
  font-family: 'gsemibold';
  src: url('../src/fonts/Galxe-Lader-SemiBold.ttf');
}

html {
  scroll-behavior: smooth;
}

body {
  background: #F3F3F3;
  min-height: 100vh;
  font-family: 'Galaxy', sans-serif;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

.cursor-pointer {
  cursor: pointer;
}

iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

.custom-container {
  max-width: 1350px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}



.modal-backdrop {
  opacity: 0 !important;
}

.connectwallet-modal {
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(7px);

  .modal-dialog {
    max-width: 480px;
  }

  .modal-content {
    position: relative;
    border-radius: 10px;
    background: #F3F3F3;
    padding: 25px;


  }

  .modal-header {
    padding: 0;
    border: none;
    padding-bottom: 25px;

    .modal-title {
      color: #013159;
      font-size: 18px;
      font-style: normal;
      font-family: "gmedium";
      line-height: 120%;
    }

    .btn-close {
      background: url("../src/assets/btn-close.svg") !important;
      opacity: 1 !important;
      box-shadow: none;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .modal-body {
    padding: 0;

    .connectwallet-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 13px;
      width: 100%;
      border-radius: 20px;
      border: 1px solid #E0E5E9;
      background: rgba(255, 255, 255, 0.03);
      padding: 40px 10px;
      color: #013159;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-family: "gmedium";
      line-height: normal;

      transition: 0.3s linear;

      &:hover {
        border-radius: 20px;
        border: 1.5px solid #013159;
        background: rgba(255, 255, 255, 0.03);
      }

    }
  }
}

@media (max-width:600px){
  .connectwallet-modal .modal-body .connectwallet-btns{
    flex-direction: column;
  }
}

.nodata-text {
  color: #013159;
  font-size: 30px;
  font-style: normal;
  font-family: "gmedium";
  line-height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  width: 100%;
}

@media (max-width:600px) {
  .nodata-text {
      font-size: 18px;
  }
}
// h1 {
//   color: white;
// }
button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  color: white;
}

.btn-dispute {
  background-color: #ff5733; /* Red */
}

.btn-proof-work {
  background-color: #28a745; /* Green */
}

.btn-success {
  background-color: #007bff; /* Blue */
}

.btn-withdraw {
  background-color: #ffc107; /* Yellow */
}

.btn-successfully {
  background-color: #17a2b8; /* Teal */
}

.btn-profile-edited {
  background-color: #6f42c1; /* Purple */
}

.btn-task-created {
  background-color: #dc3545; /* Dark Red */
}

.btn-select-winner {
  background-color: #fd7e14; /* Orange */
}

.btn-winner-selected {
  background-color: #20c997; /* Greenish */
}

.btn-proof-work-no-btn {
  background-color: #343a40; /* Dark Gray */
}

.btn-withdraw-tomi {
  background-color: #6c757d; /* Gray */
}

.modal_mainr {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(7px);

  .modal-dialog {
    max-width: 418px;
    .modal-content {
      max-width: 100%;
      border-radius: 10px;
      background: #f3f3f3;

      .btn-close {
        background-image: url(../../assets/btn-close.png);
        width: 30px;
        height: 30px;
        position: absolute;
        right: 25px;
        flex-shrink: 0;
        padding: 0;
        opacity: unset;
        background-size: contain;
        outline: unset;
        box-shadow: unset;
      }
      .modal-title {
        margin-bottom: 0;
        line-height: var(--bs-modal-title-line-height);
        color: #013159;
        /* font-family: "Galxe Lader"; */
        font-size: 18px;
        font-style: normal;
        font-family: 'gmedium';
        line-height: 120%;
      }
      .modal-body {
        padding: 25px;

        p.inermsgpars {
          color: #627f97;
          // font-family: "Galxe Lader";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.28px;
        }
        .modalfst {
          .form-control {
            border-radius: 15px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            background: rgba(240, 243, 247, 0.05);
            color: #8fa3b4;
            padding: 18px;
            outline: unset;
            box-shadow: unset;
          }

          ::placeholder {
            color: #8fa3b4;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 16px */
            letter-spacing: -0.32px;
          }

          h1 {
            color: #013159;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
            /* 27.2px */
            letter-spacing: -0.32px;
            padding-top: 5px;
            padding-bottom: 10px;
          }

          .modal3_inner {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 25px 28px;
            border-radius: 15px;
            border: 1px dashed rgba(0, 0, 0, 0.06);
            background: rgba(240, 243, 247, 0.05);
            // margin-bottom: 18px;

            h2 {
              color: #013159;
              text-align: center;

              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 16px */
              letter-spacing: -0.32px;
            }

            p {
              color: #013159;
              text-align: center;

              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              /* 14px */
              letter-spacing: -0.28px;
              padding-top: 15px;
              padding-bottom: 20px;
            }

            .ChooseFile {
              color: #013159;
              text-align: center;

              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              /* 18px */
              letter-spacing: -0.24px;
              text-transform: capitalize;
              height: 40px;
              padding: 11px 18px;
              border-radius: 120px;
              background: #e6e9eb;
              border: unset;
            }
          }
        }
        .modal1 {
          display: flex;
          align-items: center;
          flex-direction: column;

          .imgqus {
            width: 116px;
            height: 116px;
          }

          h1 {
            color: #013159;
            text-align: center;
            font-family: "gsemibold";
            font-size: 22px;
            font-style: normal;
            line-height: 120%; /* 26.4px */
            letter-spacing: -0.44px;
            padding-top: 25px;
          }

          p {
            color: #013159;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
            /* 27.2px */
            letter-spacing: -0.32px;
            padding-top: 15px;
          }
        }

        .modal2 {
          display: flex;
          align-items: center;
          flex-direction: column;

          .imgqus {
            width: 116px;
            height: 116px;
          }

          h1 {
            color: #013159;
            text-align: center;

            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 26.4px */
            letter-spacing: -0.44px;
            padding-top: 25px;
            padding-bottom: 25px;
          }

          p {
            color: #013159;
            text-align: center;

            font-size: 16px;
            padding-bottom: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 16px */
            letter-spacing: -0.32px;
          }

          h2 {
            color: #013159;
            text-align: center;

            padding: 20px;
            border-radius: 10px;
            background: rgba(240, 243, 247, 0.05);
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 170%;
            /* 37.4px */
            letter-spacing: -0.44px;
          }

          h3 {
            color: #013159;
            text-align: center;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
            /* 27.2px */
            letter-spacing: -0.32px;
            padding-top: 25px;
          }
        }
        .mainscrooldiv2nd {
          // border-radius: 31px;
          // background: #013159;
          border: 1px solid #e0e5e9;

          margin-top: 15px;
          border-radius: 9px;
          background: #f9f9f9;
          .inerscrooldiv2nd {
            display: flex;
            padding: 20px;
            justify-content: space-between;
            align-items: center;

            p.dddooeenger {
              color: #013159;
              text-align: center;
              /* font-family: "Galxe Lader"; */
              font-size: 14px;
              font-style: normal;
              font-family: 'gmedium';
              line-height: 100%;
              letter-spacing: -0.28px;
            }
            p.dddooeengerright {
              display: flex;
              align-items: center;
              gap: 10px;
              color: #013159;
              text-align: center;
              /* font-family: "Galxe Lader"; */
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              letter-spacing: -0.28px;
              text-decoration-line: underline;
            }
          }
          .secdiv2nd {
            display: flex;
            align-items: center;
            gap: 5px;
            img.inerimgg {
              border-radius: 48px;
              border: 0.632px solid #f9f9f9;

              max-width: 24px;
              height: 24px;
              width: 100%;
            }
            p.namewithimg {
              color: #013159;
              /* font-family: "Galxe Lader"; */
              font-size: 14px;
              font-style: normal;
              font-family: 'gmedium';
              line-height: 120%;
            }
          }
        }
        .modal3 {
          .subnmatouterdiv {
            border-radius: 9px;
            background: #f9f9f9;
            display: flex;
            padding: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
            p.submitted {
              color: #627f97;

              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              text-transform: uppercase;
            }
            .secdiv {
              display: flex;
              align-items: center;
              gap: 5px;
              img.inerimgg {
                border-radius: 48px;
                border: 0.632px solid #f9f9f9;

                max-width: 24px;
                height: 24px;
                width: 100%;
              }
              p.namewithimg {
                color: #013159;
                /* font-family: "Galxe Lader"; */
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
              }
            }
          }
          .msgdiv {
            // padding-bottom: 15px;
            .msghfdng {
              color: #013159;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 170%;
              letter-spacing: -0.32px;
              padding-top: 15px;
              padding-bottom: 15px;
            }
          }
          .mainscrooldiv {
            // border-radius: 31px;
            // background: #013159;
            border: 1px solid #e0e5e9;
            height: 200px;
            overflow-y: scroll;
            margin-top: 15px;
          }

          /* Custom scrollbar styles */
          .mainscrooldiv::-webkit-scrollbar {
            width: 8px;
            border-radius: 30px;
          }

          .mainscrooldiv::-webkit-scrollbar-track {
            background: #e0e5e9; /* Scrollbar track background */
            border-radius: 11px;
          }

          .mainscrooldiv::-webkit-scrollbar-thumb {
            background-color: #013159; /* Scrollbar thumb color */
            border-radius: 31px;
            border: 2px solid #e0e5e9; /* Thumb border */
          }

          /* For Firefox */
          .mainscrooldiv {
            scrollbar-color: #e0e5e9; /* Thumb color and track color */
            // scrollbar-width: thin; /* Makes the scrollbar smaller */
          }

          .mainscrooldiv {
            border: 1px solid #e0e5e9;
            border-radius: 11px;
            height: 200px;
            overflow-y: scroll;
            .inerscrooldiv {
              display: flex;
              padding: 15px 35px 15px 20px;
              justify-content: space-between;
              align-items: center;

              border-bottom: 1px solid #e0e5e9;
              p.dddooeenger {
                color: #013159;
                text-align: center;
                /* font-family: "Galxe Lader"; */
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.28px;
              }
              p.dddooeengerright {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #013159;
                text-align: center;
                /* font-family: "Galxe Lader"; */
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.28px;
                text-decoration-line: underline;
              }
            }
          }
          .form-control {
            border-radius: 15px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            background: rgba(240, 243, 247, 0.05);
            color: #013159;
            padding: 18px;
            outline: unset;
            box-shadow: unset;
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.4);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 16px */
            letter-spacing: -0.32px;
          }

          h1 {
            color: #013159;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
            /* 27.2px */
            letter-spacing: -0.32px;
            padding-top: 5px;
            padding-bottom: 10px;
          }

          .modal3_inner {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 25px 28px;
            border-radius: 16px;
            border: 1px dashed rgba(0, 0, 0, 0.06);
            background: rgba(240, 243, 247, 0.05);
            // margin-bottom: 18px;

            h2 {
              color: #013159;
              text-align: center;

              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 100%;
              /* 16px */
              letter-spacing: -0.32px;
            }

            p {
              color: #013159;
              text-align: center;

              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              /* 14px */
              letter-spacing: -0.28px;
              padding-top: 15px;
              padding-bottom: 20px;
            }

            .ChooseFile {
              color: #013159;
              text-align: center;

              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              /* 18px */
              letter-spacing: -0.24px;
              text-transform: capitalize;
              height: 40px;
              padding: 11px 18px;
              border-radius: 120px;
              background: #ff0083;
              border: unset;
            }
          }
        }

        .modal4 {
          display: flex;
          align-items: center;
          flex-direction: column;

          .imgqus {
            width: 116px;
            height: 116px;
          }

          h1 {
            color: #013159;
            text-align: center;

            font-size: 22px;
            font-style: normal;
            font-family: 'gmedium' !important;
            line-height: 120%;
            /* 26.4px */
            letter-spacing: -0.44px;
            padding-top: 25px;
            padding-bottom: 15px;
          }

            .parssa {
          color: #627f97;
          // font-family: "Galxe Lader";
          font-size: 14px;
          font-style: normal;
          font-family: 'gregular';
          line-height: 120%;
          letter-spacing: -0.28px;
          text-align: center;
        }

          h2 {
            color: #013159;
            text-align: center;

            padding: 20px;
            border-radius: 10px;
            background: rgba(240, 243, 247, 0.05);
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 170%;
            /* 37.4px */
            letter-spacing: -0.44px;
          }

          h3 {
            color: #013159;
            text-align: center;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
            /* 27.2px */
            letter-spacing: -0.32px;
            padding-top: 25px;
          }
        }
      }

      .modal-header {
        position: relative;
        padding: 25px;
        padding-bottom: 0px;
        color: #013159;

        font-size: 18px;
        font-style: normal;
        // line-height: 120%;
        /* 21.6px */
        border-bottom: unset;
      }

      .modal-footer {
        // padding: 25px;
        padding-top: unset;

        .modal_footer {
          display: flex;
          margin: 0px !important;
          align-items: center;
          gap: 20px;
          width: 100%;
        }

        border-top: unset;

        .cancelbutton {
          color: #013159;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          width: 100%;
          /* 24px */
          background: #dbe0e4;
          letter-spacing: -0.32px;
          border-radius: 100px;
          border: none;
          padding: 14px 25px;
        }

        .surebutton {
          color: #f9f9f9;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-family: 'gmedium';
          width: 100%;
          line-height: 150%;
          /* 24px */
          letter-spacing: -0.32px;
          padding: 14px 25px;
          border: unset;
          border-radius: 100px;
          background: #013159;
        }
      }
    }
  }
}


.dcscysysbc{
  .modal-dialog{
      .modal-content{
          max-width: 500px !important;
      }
  }
}
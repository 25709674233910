.taskscreated {
    padding-top: 118px;
    padding-bottom: 77px;
    position: relative;

    .backshade {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    .createdhead {
        color: #013159;
        font-family: "gmedium";
        font-size: 30px;
        font-style: normal;
        line-height: 150%;
        margin-bottom: 25px;
        z-index: 9;
        position: relative;
    }

    .toppara {
        color: #013159;
        font-family: "gmedium";
        font-size: 14px;
        font-style: normal;
        line-height: 150%;
        text-transform: uppercase;
        z-index: 9;
        margin-bottom: 25px;
        position: relative;

        .light {
            color: #627F97;
            font-family: "gmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 150%;
            text-transform: uppercase;
        }
    }

    .createdtasktable {
        width: 100%;
        border-radius: 14px;
        border: 1px solid #E0E5E9;
        background: #FFF;
        backdrop-filter: blur(35.099998474121094px);
        z-index: 9;
        position: relative;

        table {
            width: 100%;

            thead {
                th {
                    color: #013159;
                    font-family: "gmedium";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 120%;
                    letter-spacing: 0.1px;
                    height: 66px;
                    padding: 15px 20px;
                    background-color: transparent;
                }
            }

            tbody {
                tr {
                    td {
                        color: #013159;
                        font-family: "gmedium";
                        font-size: 14px;
                        font-style: normal;
                        line-height: 120%;
                        letter-spacing: 0.1px;
                        height: 66px;
                        padding: 20px;
                        border-top: 1px solid #E0E5E9;
                        background-color: transparent;

                        .taskpara {
                            max-width: 173px;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-decoration: underline;
                        }

                        .progressbtn {
                            display: flex;
                            padding: 8px 10px;
                            justify-content: center;
                            align-items: center;
                            color: #FF9500;
                            font-family: "gmedium";
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 0.2px;
                            border-radius: 85px;
                            background: rgba(255, 149, 0, 0.22);
                            border: none;
                            max-width: fit-content;
                        }

                        .reviewbtn {
                            display: flex;
                            padding: 8px 10px;
                            justify-content: center;
                            align-items: center;
                            color: #3EDD1E;
                            font-family: "gmedium";
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 0.2px;
                            border-radius: 75px;
                            background: rgba(99, 228, 73, 0.22);
                            border: none;
                            max-width: fit-content;
                        }

                        .endedbtn {
                            display: flex;
                            padding: 8px 10px;
                            justify-content: center;
                            align-items: center;
                            color: #4987E4;
                            font-family: "gmedium";
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 0.2px;
                            border-radius: 115px;
                            background: rgba(73, 135, 228, 0.22);
                            border: none;
                            max-width: fit-content;
                        }

                        .disputedbtn {
                            display: flex;
                            padding: 8px 10px;
                            justify-content: center;
                            align-items: center;
                            color: #E44C49;
                            font-family: "gmedium";
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 0.2px;
                            border-radius: 115px;
                            background: rgba(228, 76, 73, 0.22);
                            border: none;
                            max-width: fit-content;
                        }
                    }
                }
            }
        }

        .paginationmain {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 20px 14px 20px;
            border-top: 1px solid #E0E5E9;

            .paginationtext {
                color: #013159;
                font-family: "gmedium";
                font-size: 14px;
                font-style: normal;
                line-height: 120%;
                letter-spacing: 0.1px;
                text-decoration-line: underline;
            }

            .pagination {
                background-color: transparent;
                border: none;
                border-radius: 0;

                .active>.page-link,
                .page-link.active {
                    color: #FFF !important;
                    background-color: #013159 !important;
                }

                .page-item {
                    .page-link {
                        display: flex;
                        padding: 6px 11px;
                        align-items: center;
                        border-radius: 5px;
                        background-color: transparent;
                        border: none;
                        color: #8C8C8C;
                        font-size: 15px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        box-shadow: none;
                        outline: none;
                        margin-left: 2px;

                        &:hover {
                            background-color: #013159 !important;
                            color: #FFF !important;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:991px) {
    .taskscreated {
        padding-top: 135px;
    }

    .taskscreated .createdhead {
        font-size: 26px;
        margin-bottom: 40px;
    }

    .taskscreated .createdtasktable {
        display: none;
    }

    .taskscreated .createdtaskmbl {
        display: block !important;
        border-radius: 14px;
        border: 1px solid #E0E5E9;
        background: #FFF;
        backdrop-filter: blur(35.099998474121094px);

        .acctop {
            padding: 0px 15px;
            border-bottom: 1px solid #E0E5E9;
            display: flex;
            height: 66px;
            align-items: center;
            justify-content: space-between;

            .taskpara {
                color: #013159;
                font-size: 12px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 110%;
                letter-spacing: 0.24px;
                width: 100%;
            }
        }

        .accordion {
            background-color: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            border-radius: 0;
            padding: 0;
            margin: 0;

            .accordion-item {
                background-color: transparent;
                border: none;
                box-shadow: none;
                outline: none;
                border-radius: 0;
                padding: 0;
                margin: 0;

                .accordion-header {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    border-radius: 0;
                    padding: 0;
                    margin: 0;

                    .accordion-button::after {
                        display: none;
                    }

                    .accordion-button {
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                        outline: none;
                        border-radius: 0;
                        padding: 0 15px;
                        margin: 0;
                        height: 66px;
                        border-bottom: 1px solid #E0E5E9;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .taskmain {
                            width: 100%;

                            .innertaskpara {
                                color: #013159;
                                font-size: 14px;
                                text-decoration: underline;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 120%;
                                letter-spacing: 0.1px;
                                width: 100%;
                                max-width: 155px;
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        .statusmain {
                            width: 100%;

                            .progressbtn {
                                display: flex;
                                padding: 8px 10px;
                                justify-content: center;
                                align-items: center;
                                color: #FF9500;
                                font-family: "gmedium";
                                font-size: 10px;
                                font-style: normal;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                                border-radius: 85px;
                                background: rgba(255, 149, 0, 0.22);
                                border: none;
                                max-width: fit-content;
                            }

                            .reviewbtn {
                                display: flex;
                                padding: 8px 10px;
                                justify-content: center;
                                align-items: center;
                                color: #3EDD1E;
                                font-family: "gmedium";
                                font-size: 10px;
                                font-style: normal;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                                border-radius: 75px;
                                background: rgba(99, 228, 73, 0.22);
                                border: none;
                                max-width: fit-content;
                            }

                            .endedbtn {
                                display: flex;
                                padding: 8px 10px;
                                justify-content: center;
                                align-items: center;
                                color: #4987E4;
                                font-family: "gmedium";
                                font-size: 10px;
                                font-style: normal;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                                border-radius: 115px;
                                background: rgba(73, 135, 228, 0.22);
                                border: none;
                                max-width: fit-content;
                            }

                            .disputedbtn {
                                display: flex;
                                padding: 8px 10px;
                                justify-content: center;
                                align-items: center;
                                color: #E44C49;
                                font-family: "gmedium";
                                font-size: 10px;
                                font-style: normal;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                                border-radius: 115px;
                                background: rgba(228, 76, 73, 0.22);
                                border: none;
                                max-width: fit-content;
                            }
                        }

                    }
                }

                .accordion-body {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    border-radius: 0;
                    padding: 15px;
                    margin: 0;
                    border-bottom: 1px solid #E0E5E9;

                    .mainacc {
                        display: flex;
                        flex-direction: column;
                        gap: 13px;
                        // padding-bottom: 23px;

                        .inneracc {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .leftacc {
                                color: #013159;
                                font-size: 14px;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 120%;
                                letter-spacing: 0.1px;
                            }

                            .rightacc {
                                color: #013159;
                                font-size: 14px;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 120%;
                                letter-spacing: 0.1px;
                            }
                        }
                    }

                    .detailbtn {
                        border-radius: 50px;
                        background: #FF0083;
                        display: flex;
                        padding: 16px 13px;
                        justify-content: center;
                        align-items: center;
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        width: 100%;
                        letter-spacing: 0.24px;
                        text-transform: uppercase;
                        border: none;
                    }
                }
            }
        }

        .paginationmain {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 13px 20px 14px 20px;

            .paginationtext {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 120%;
                letter-spacing: 0.1px;
            }

            .pagination {
                background-color: transparent;
                border: none;
                border-radius: 0;

                .active>.page-link,
                .page-link.active {
                    color: #FFF !important;
                    background-color: #013159 !important;
                }

                .page-item {
                    .page-link {
                        display: flex;
                        padding: 6px 11px;
                        align-items: center;
                        border-radius: 5px;
                        background-color: transparent;
                        border: none;
                        color: #8C8C8C;
                        font-size: 15px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        box-shadow: none;
                        outline: none;
                        margin-left: 2px;

                        &:hover {
                            color: #FFF !important;
                            background-color: #013159 !important;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:600px) {
    .taskscreated .backshade{
        display: none !important;
    }
    .taskscreated .backshadembl{
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
}
.main-footer {
    background: #F3F3F3;
    padding: 30px 0px;

    .mainparentsss {
        .mainimage {
            width: 158.463px;
            height: 45.785px;
            object-fit: cover;
            object-position: center;
            overflow: hidden;

            .footorimg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .bootom {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h6 {
                color: #627F97;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: 'gregular';
                font-size: 12px;
                font-style: normal;
                line-height: 120%;
                /* 14.4px */
                text-transform: capitalize;
            }

            .tomitext {
                color: #013159;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: 'gmedium';
                font-size: 12px;
                font-style: normal;
                line-height: 120%;
                /* 14.4px */
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 7px;
                text-transform: capitalize;
            }
        }



    }
}
.tasksdetails {
    padding-top: 118px;
    padding-bottom: 60px;
    position: relative;
    background-color: #FFF;

    .backshade {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    .toppara {
        color: #013159;
        font-family: "gmedium";
        font-size: 14px;
        font-style: normal;
        line-height: 150%;
        text-transform: uppercase;
        z-index: 9;
        margin-bottom: 25px;
        position: relative;

        .light {
            color: #627F97;
            font-family: "gmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 150%;
            text-transform: uppercase;
        }
    }

    .detailmain {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 170px;
        margin-bottom: 220px;
        z-index: 99;
        position: relative;

        .detailsleft {

            .statusmain {
                border-radius: 136px;
                background: rgba(255, 0, 131, 0.10);
                display: flex;
                padding: 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                max-width: fit-content;
                margin-bottom: 10px;

                .statuscircle {
                    width: 10px;
                    height: 10px;
                    background-color: #FF0083;
                    border-radius: 100px;
                }

                .statuspara {
                    color: #FF0083;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                }
            }

            .detailhead {
                color: #013159;
                font-size: 30px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 150%;
                margin-bottom: 20px;
            }

            .detailpara {
                color: #013159;
                font-size: 18px;
                font-style: normal;
                font-family: "gregular";
                line-height: 150%;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .detailsright {
            .innerdetailsright {
                border-radius: 14px;
                border: 1px solid #E0E5E9;
                background: #FFF;
                padding: 30px;
                margin-bottom: 20px;
                min-width: 487px;

                .reviewhead {
                    color: #013159;
                    font-family: "gmedium";
                    font-size: 18px;
                    font-style: normal;
                    line-height: 120%;
                    margin-bottom: 20px;
                }

                .reviewpara {
                    color: #627F97;
                    font-family: "gregular";
                    font-size: 12px;
                    font-style: normal;
                    line-height: 130%;
                    margin-bottom: 20px;
                }

                .redpara {
                    color: #FE0000;
                    font-family: "gregular";
                    font-size: 12px;
                    font-style: normal;
                    line-height: 130%;
                    margin-top: 20px;
                    text-align: center;
                }

                .maintimer {
                    border-radius: 9px;
                    background: #F9F9F9;
                    padding: 20px 0px;

                    .endpara {
                        color: #627F97;
                        font-family: "gregular";
                        font-size: 12px;
                        font-style: normal;
                        line-height: 130%;
                        text-transform: uppercase;
                        text-align: center;
                        margin-bottom: 10px;
                    }

                    .timerspans {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 3px;

                        .colonpara {
                            color: #013159;
                            font-family: "gmedium";
                            font-size: 18px;
                            font-style: normal;
                            line-height: 120%;
                            text-align: center;
                        }

                        .seconds {
                            width: 50px;
                        }

                        .maintime {
                            .innertimer {
                                .innertimertext {
                                    color: #013159;
                                    font-family: "gmedium";
                                    font-size: 18px;
                                    font-style: normal;
                                    line-height: 120%;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                .detailtop {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;

                    .rewardpara {
                        color: #627F97;
                        font-family: "gregular";
                        font-size: 12px;
                        font-style: normal;
                        line-height: 130%;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                    }

                    .rewardamount {
                        color: #013159;
                        font-family: "gmedium";
                        font-size: 14px;
                        font-style: normal;
                        line-height: 120%;
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        .rewardimg {
                            width: 24px;
                            height: 24px;
                            object-fit: cover;
                            object-position: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 120px;

                            .innerrewardimg {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                                border-radius: 120px;
                            }
                        }
                    }

                    .participantpara {
                        color: #627F97;
                        font-family: "gregular";
                        font-size: 12px;
                        font-style: normal;
                        line-height: 130%;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                    }

                    .partcipantmainhead {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;

                        .particpantnumber {
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 170%;
                            letter-spacing: -0.32px;
                        }


                        .two {
                            position: absolute;
                            left: 15px;
                            top: 0;
                        }

                        .three {
                            position: absolute;
                            left: 30px;
                            top: 0;
                        }

                        .four {
                            position: absolute;
                            left: 45px;
                            top: 0;
                        }

                        .five {
                            position: absolute;
                            left: 60px;
                            top: 0;

                            .numberimages {
                                color: #013159;
                                text-align: center;
                                font-family: "gmedium";
                                font-size: 12px;
                                font-style: normal;
                                line-height: 120%;
                            }
                        }

                        .participantmain {
                            width: 24px;
                            height: 24px;
                            border-radius: 48px;
                            border: 1px solid #F9F9F9;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            object-fit: cover;
                            object-position: center;
                            overflow: hidden;
                            background: #FFF;

                            .participantimg {
                                width: 100%;
                                height: 100%;
                                border-radius: 76px;
                                object-fit: cover;
                                object-position: center;
                                overflow: hidden;
                            }
                        }
                    }
                }

                .withdrawbtn {
                    border-radius: 100px;
                    background: #013159;
                    display: flex;
                    padding: 15px 25px;
                    justify-content: center;
                    align-items: center;
                    color: #FFF;
                    font-family: "gmedium";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 100%;
                    border: none;
                    width: 100%;
                }

                .fundwithdrawnbtn {
                    display: flex;
                    padding: 15px 25px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: #013159;
                    font-family: "gmedium";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 100%;
                    border-radius: 100px;
                    border: 1px solid #013159;
                    background-color: transparent;
                    width: 100%;
                }

                .winnermain {
                    border-radius: 9px;
                    background: #F9F9F9;
                    display: flex;
                    padding: 20px;
                    justify-content: space-between;
                    align-items: center;

                    .winnerpara {
                        color: #013159;
                        font-family: "gmedium";
                        font-size: 16px;
                        font-style: normal;
                        line-height: 170%;
                        letter-spacing: -0.32px;
                    }

                    .winnermainhead {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 6px;

                        .winnermainimg {
                            width: 33px;
                            height: 33px;
                            object-fit: cover;
                            object-position: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 120px;

                            .winnerimg {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                                border-radius: 120px;
                            }
                        }

                        .winnerwallet {
                            color: #013159;
                            font-family: "gmedium";
                            font-size: 16px;
                            font-style: normal;
                            line-height: 170%;
                            letter-spacing: -0.32px;
                        }
                    }
                }

                .createdbypara {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    letter-spacing: -0.32px;
                    margin-bottom: 15px;
                }

                .creatormain {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;

                    .creatormainimg {
                        border-radius: 56px;
                        border: 1px solid rgba(255, 255, 255, 0.00);
                        width: 38px;
                        height: 38px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: cover;
                        object-position: center;

                        .creatorimg {
                            border-radius: 56px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .creatorwallet {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 170%;
                        letter-spacing: -0.32px;
                    }
                }
            }

            .votebox {
                border-radius: 14px;
                border: 1px solid #E0E5E9;
                background: #FFF;
                padding: 30px;
                margin-bottom: 20px;
                min-width: 487px;

                .mainheading {
                    color: #013159;
                    font-family: 'gmedium';
                    font-size: 18px;
                    font-style: normal;
                    margin-bottom: 20px;
                    line-height: 120%
                }

                .votes {
                    .parentvote {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0px
                        }

                        .parentleft {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 8px;

                            .innerleft {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 6px;

                                .mainimage {
                                    width: 22px;
                                    height: 22px;
                                    object-fit: cover;
                                    object-position: center;
                                    overflow: hidden;
                                    border-radius: 120px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .innervoteimg {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        object-position: center;
                                    }
                                }

                                .texttttttttt {
                                    color: #013159;
                                    font-family: 'gmedium';
                                    font-size: 14px;
                                    font-style: normal;
                                    line-height: 100%;
                                    /* 14px */
                                    letter-spacing: -0.28px;
                                }
                            }

                            .innerright {
                                .creator {
                                    color: #627F97;
                                    font-family: 'gregular';
                                    font-size: 12px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }

                        .parentright {
                            h6 {
                                color: #013159;
                                font-family: 'gmedium';
                                font-size: 12px;
                                font-style: normal;
                                line-height: 130%;
                            }
                        }
                    }
                }

                .brdr {
                    background: rgba(1, 49, 89, 0.10);
                    height: 1px;
                    max-width: 432px;
                    width: 100%;
                    margin: 20px 0px;
                }

                .cardprogress {
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                    width: 100%;


                    .progress {
                        border-radius: 77px;
                        // background: #FE0000;
                        height: 10px;
                        border: none;
                        // height: 31px;

                        .progress-bar {
                            background: #3EDD1E;
                            // border-radius: 50px;

                        }
                        .progress-creator {
                            background: #3EDD1E;
                            // border-radius: 50px;

                        }
                        .progress-remaining {
                            background: #FE0000;
                            // border-radius: 50px;

                        }
                    }


                }

                .lasttextpara {
                    margin-top: 20px;

                    .innerrlast {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .left {
                            p {
                                color: #013159;
                                font-family: 'gmedium';
                                font-size: 12px;
                                font-style: normal;
                                line-height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 6px;
                                letter-spacing: -0.24px;
                            }
                        }

                        .right {
                            .votepara {
                                color: #013159;
                                font-family: 'gmedium';
                                font-size: 12px;
                                font-style: normal;
                                line-height: 130%;
                            }
                        }
                    }
                }
            }
        }
    }

    .votecateatortable {
        margin-bottom: 50px;

    }

    .mainpartcipanthead {
        color: #013159;
        font-family: "gmedium";
        font-size: 30px;
        font-style: normal;
        line-height: 150%;
        padding-bottom: 25px;
        position: relative;
        z-index: 9;
    }

    .createdtasktable {
        width: 100%;
        border-radius: 14px;
        border: 1px solid #E0E5E9;
        background: #FFF;
        backdrop-filter: blur(35.099998474121094px);
        z-index: 9;
        position: relative;

        table {
            width: 100%;

            thead {
                th {
                    color: #013159;
                    font-family: "gmedium";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 120%;
                    letter-spacing: 0.1px;
                    height: 66px;
                    padding: 15px 20px;
                    background-color: transparent;
                }
            }

            tbody {
                tr {
                    td {
                        color: #013159;
                        font-family: "gmedium";
                        font-size: 14px;
                        font-style: normal;
                        line-height: 120%;
                        letter-spacing: 0.1px;
                        height: 66px;
                        padding: 20px;
                        border-top: 1px solid #E0E5E9;
                        background-color: transparent;
                        text-transform: capitalize;


                        .viewpara {
                            text-decoration-line: underline;
                            color: #013159;
                            font-family: "gmedium";
                            font-size: 14px;
                            font-style: normal;
                            line-height: 120%;
                            letter-spacing: 0.1px;
                            background: transparent;
                            border: none;
                            padding: 0;
                            margin: 0;
                        }

                        .taskpara {
                            max-width: 173px;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-decoration: underline;
                        }

                        .progressbtn {
                            display: flex;
                            padding: 8px 10px;
                            justify-content: center;
                            align-items: center;
                            color: #FF9500;
                            font-family: "gmedium";
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 0.2px;
                            border-radius: 85px;
                            background: rgba(255, 149, 0, 0.22);
                            border: none;
                            max-width: fit-content;
                        }

                        .reviewbtn {
                            display: flex;
                            padding: 8px 10px;
                            justify-content: center;
                            align-items: center;
                            color: #3EDD1E;
                            font-family: "gmedium";
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 0.2px;
                            border-radius: 75px;
                            background: rgba(99, 228, 73, 0.22);
                            border: none;
                            max-width: fit-content;
                        }

                        .endedbtn {
                            display: flex;
                            padding: 8px 10px;
                            justify-content: center;
                            align-items: center;
                            color: #4987E4;
                            font-family: "gmedium";
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 0.2px;
                            border-radius: 115px;
                            background: rgba(73, 135, 228, 0.22);
                            border: none;
                            max-width: fit-content;
                        }

                        .disputedbtn {
                            display: flex;
                            padding: 8px 10px;
                            justify-content: center;
                            align-items: center;
                            color: #E44C49;
                            font-family: "gmedium";
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 0.2px;
                            border-radius: 115px;
                            background: rgba(228, 76, 73, 0.22);
                            border: none;
                            max-width: fit-content;
                        }

                        .votebutton {
                            border-radius: 75px;
                            background: #F0F3F5;
                            padding: 8px 10px;
                            max-width: 43px;
                            width: 100%;
                            color: #013159;
                            font-family: 'gmedium';
                            font-size: 10px;
                            font-style: normal;
                            line-height: 100%;
                            /* 10px */
                            letter-spacing: 0.2px;
                        }

                        .votebuttongreen {
                            padding: 8px 10px;
                            max-width: 65px;
                            width: 100%;
                            border-radius: 75px;
                            background: rgba(99, 228, 73, 0.22);
                            color: #3EDD1E;
                            font-family: 'gmedium';
                            font-size: 10px;
                            font-style: normal;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 6px;
                            line-height: 100%;
                            /* 10px */
                            letter-spacing: 0.2px;
                        }
                    }
                }
            }
        }

        .paginationmain {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 20px 14px 20px;
            border-top: 1px solid #E0E5E9;

            .paginationtext {
                color: #013159;
                font-family: "gmedium";
                font-size: 14px;
                font-style: normal;
                line-height: 120%;
                letter-spacing: 0.1px;
                text-decoration-line: underline;
            }

            .pagination {
                background-color: transparent;
                border: none;
                border-radius: 0;

                .active>.page-link,
                .page-link.active {
                    color: #FFF !important;
                    background-color: #013159 !important;
                }

                .page-item {
                    .page-link {
                        display: flex;
                        padding: 6px 11px;
                        align-items: center;
                        border-radius: 5px;
                        background-color: transparent;
                        border: none;
                        color: #8C8C8C;
                        font-size: 15px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        box-shadow: none;
                        outline: none;
                        margin-left: 2px;

                        &:hover {
                            background-color: #013159 !important;
                            color: #FFF !important;
                        }
                    }
                }
            }
        }
    }
}


.tasksmodal {
    .modal-dialog {
        max-width: 480px;

        .modal-content {
            border-radius: 20px;
            position: relative;
            background: linear-gradient(103.06deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.003) 110.97%), rgba(29, 29, 29, 0.05);
            -webkit-backdrop-filter: blur(35.1px);
            backdrop-filter: blur(35.1px);
            border-radius: 20px;

            &::after {
                content: '';
                position: absolute;
                inset: 0px;
                border-radius: inherit;
                background-color: #FFFFFF;
                -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                border: 1px solid transparent;
                background: -o-linear-gradient(#111111 0 0) padding-box, -o-linear-gradient(264.93deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
                background: linear-gradient(#111111 0 0) padding-box, linear-gradient(185.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
                z-index: -1;
            }

            padding: 25px;

            .modal-header {
                padding: 0;
                border: none;
                margin-bottom: 25px;

                .modal-title {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                    letter-spacing: -0.36px;
                }

                .btn-close {
                    display: flex;
                    width: 30px;
                    height: 30px;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    // background-image: url('../../assets/cross.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    border-radius: 3px;
                    background-color: rgba(240, 243, 247, 0.05);
                    opacity: 1;
                    box-shadow: none !important;
                }
            }

            .modal-body {
                padding: 0px;

                .parenttext {
                    .mainpic {
                        width: 116px;
                        height: 116px;
                        object-fit: cover;
                        object-position: center;
                        overflow: hidden;
                        margin: 0 auto;

                        .tick {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .heading {
                        color: #FFF;
                        text-align: center;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%;
                        /* 26.4px */
                        letter-spacing: -0.44px;
                        margin-top: 25px;
                    }

                    .para {
                        color: #FFF;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 170%;
                        /* 27.2px */
                        letter-spacing: -0.32px;
                        margin-top: 15px;
                        margin-bottom: 25px;
                    }

                    .workdetails {
                        border-radius: 20px;
                        background: rgba(255, 255, 255, 0.02);
                        padding: 20px;
                        margin-bottom: 25px;
                        padding-right: 10px;

                        .workscroll {
                            height: 255px;
                            overflow-y: auto;
                            flex-direction: column;
                            gap: 18px;
                            display: flex;
                            padding-right: 10px;

                            .workinner {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border-radius: 5px;
                                background: rgba(225, 233, 244, 0.03);
                                padding: 15px 20px;

                                .proofleft {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 16px;

                                    .proofimg {
                                        border-radius: 5px;
                                        background: rgba(191, 203, 222, 0.10);
                                        display: flex;
                                        width: 42px;
                                        height: 42px;
                                        justify-content: center;
                                        align-items: center;
                                    }

                                    .proofpara {
                                        color: #FFF;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 100%;
                                        letter-spacing: -0.28px;
                                    }
                                }

                                .proofright {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 10px;

                                    .downloadpara {
                                        color: #FFF;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 100%;
                                        letter-spacing: -0.28px;
                                        text-decoration-line: underline;
                                    }
                                }
                            }

                            &::-webkit-scrollbar {
                                width: 6px;
                                border-radius: 40px;
                            }

                            &::-webkit-scrollbar-track {
                                background: rgba(62, 47, 56, 0.50);
                                border-radius: 40px;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 40px;
                                background: #FF0083;
                            }


                        }
                    }

                    .winnerpara {
                        color: #FFF;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        letter-spacing: -0.32px;
                        margin-bottom: 18px;
                    }

                    .winnermain {
                        display: flex;
                        padding: 20px;
                        align-items: center;
                        gap: 6px;
                        border-radius: 10px;
                        background: rgba(240, 243, 247, 0.05);
                        max-width: fit-content;
                        justify-content: center;
                        margin: 0 auto 25px;

                        .winnermainimg {
                            border-radius: 56px;
                            border: 1px solid #CED7E4;
                            background: #FFF;
                            width: 44px;
                            height: 44px;
                            object-fit: cover;
                            object-position: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .winnerimg {
                                border-radius: 56px;
                                border: 1px solid #CED7E4;
                                background: #FFF;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }

                        .winnerparainner {
                            color: #FFF;
                            text-align: center;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 170%;
                            letter-spacing: -0.44px;
                        }
                    }

                    .modalbtn {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;

                        .cancelbtn {
                            border-radius: 50px;
                            border: 1px solid #FF0083;
                            display: flex;
                            padding: 14px 25px;
                            justify-content: center;
                            align-items: center;
                            color: #FFF;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 150%;
                            letter-spacing: -0.32px;
                            width: 100%;
                            background: transparent;
                        }

                        .surebtn {
                            border-radius: 50px;
                            background: #FF0083;
                            display: flex;
                            padding: 14px 25px;
                            justify-content: center;
                            align-items: center;
                            color: #FFF;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 150%;
                            width: 100%;
                            letter-spacing: -0.32px;
                            border: 1px solid #FF0083;
                        }
                    }

                    .ok {
                        border-radius: 60px;
                        background: #FF0083;
                        padding: 14px 25px;
                        width: 100%;
                        color: #FFF;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 150%;
                        /* 24px */
                        letter-spacing: -0.32px;
                        border: none;
                    }
                }
            }
        }
    }
}

@media(max-width:991px) {
    .tasksdetails {
        padding-top: 113px;
    }

    .tasksdetails .detailmain {
        flex-direction: column;
        gap: 50px;
        margin-bottom: 104px;
    }

    .tasksdetails .detailmain .detailsright {
        min-width: 100%;
    }

    .tasksdetails .detailmain .detailsright .votebox {
        min-width: 100%;
    }

    .tasksdetails .detailmain .detailsleft .detailhead {
        font-size: 22px;
    }

    .tasksdetails .detailmain .detailsleft .detailpara {
        font-size: 14px;
    }

    .tasksdetails .toppara {
        font-size: 12px;
    }

    .tasksdetails .toppara .light {
        font-size: 12px;
    }

    .tasksdetails .createdtasktable .paginationmain {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tasksdetails .createdtasktable table tbody tr td {
        font-size: 12px;
        padding: 20px 15px;
    }

    .tasksdetails .createdtasktable table thead th {
        font-size: 12px;
        padding: 15px;
    }

    .tasksdetails .detailmain .detailsright .innerdetailsright {
        min-width: 100%;
    }

    .tasksdetails .mainpartcipanthead {
        font-size: 22px;
        padding-bottom: 20px;
    }

    .tasksdetails .createdtasktable .paginationmain .paginationtext {
        display: none;
    }

    .tasksdetails .backshade {
        display: none !important;
    }

    .tasksdetails .backshadembl {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    .createdtaskmbl {
        display: block !important;
        border-radius: 14px;
        border: 1px solid #E0E5E9;
        background: #FFF;
        backdrop-filter: blur(35.099998474121094px);

        .acctop {
            padding: 0px 15px;
            border-bottom: 1px solid #E0E5E9;
            display: flex;
            height: 66px;
            align-items: center;
            justify-content: space-between;

            .taskpara {
                color: #013159;
                font-size: 12px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 110%;
                letter-spacing: 0.24px;
                width: 100%;
            }
        }

        .accordion {
            background-color: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            border-radius: 0;
            padding: 0;
            margin: 0;

            .accordion-item {
                background-color: transparent;
                border: none;
                box-shadow: none;
                outline: none;
                border-radius: 0;
                padding: 0;
                margin: 0;

                .accordion-header {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    border-radius: 0;
                    padding: 0;
                    margin: 0;

                    .accordion-button::after {
                   background: url(../../../src/assets/bgvector.png);
                   background-position: center;
                   background-repeat: no-repeat;
                   background-size: cover;
                   min-width: 6px;
                   min-height: 3px;
                   width: 6px;
                   height: 3px;
                   padding: 0;
                   margin: 0;
                    }

                    .accordion-button {
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                        outline: none;
                        border-radius: 0;
                        padding: 0 15px;
                        margin: 0;
                        height: 66px;
                        border-bottom: 1px solid #E0E5E9;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .picture {
                            min-height: 3px;
                            min-width: 6px;
                        }

                        .taskmain {
                            width: 100%;

                            .innertaskpara {
                                color: #013159;
                                font-size: 14px;

                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 120%;
                                letter-spacing: 0.1px;
                                width: 100%;
                                max-width: 155px;
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;

                            }
                        }

                        .statusmain {
                            width: 100%;

                            .progressbtn {
                                display: flex;
                                padding: 8px 10px;
                                justify-content: center;
                                align-items: center;
                                color: #FF9500;
                                font-family: "gmedium";
                                font-size: 10px;
                                font-style: normal;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                                border-radius: 85px;
                                background: rgba(255, 149, 0, 0.22);
                                border: none;
                                max-width: fit-content;
                            }

                            .reviewbtn {
                                display: flex;
                                padding: 8px 10px;
                                justify-content: center;
                                align-items: center;
                                color: #3EDD1E;
                                font-family: "gmedium";
                                font-size: 10px;
                                font-style: normal;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                                border-radius: 75px;
                                background: rgba(99, 228, 73, 0.22);
                                border: none;
                                max-width: fit-content;
                            }

                            .endedbtn {
                                display: flex;
                                padding: 8px 10px;
                                justify-content: center;
                                align-items: center;
                                color: #4987E4;
                                font-family: "gmedium";
                                font-size: 10px;
                                font-style: normal;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                                border-radius: 115px;
                                background: rgba(73, 135, 228, 0.22);
                                border: none;
                                max-width: fit-content;
                            }

                            .disputedbtn {
                                display: flex;
                                padding: 8px 10px;
                                justify-content: center;
                                align-items: center;
                                color: #E44C49;
                                font-family: "gmedium";
                                font-size: 10px;
                                font-style: normal;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                                border-radius: 115px;
                                background: rgba(228, 76, 73, 0.22);
                                border: none;
                                max-width: fit-content;
                            }
                        }

                    }
                }

                .accordion-body {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    border-radius: 0;
                    padding: 15px;
                    margin: 0;
                    border-bottom: 1px solid #E0E5E9;

                    .mainacc {
                        display: flex;
                        flex-direction: column;
                        gap: 13px;

                        // padding-bottom: 23px;
                        .forvote {
                            border-radius: 75px;
                            background: #F0F3F5;
                            padding: 14px 10px;
                            width: 100%;
                            color: #013159;
                            font-family: 'gmedium';
                            font-size: 12px;
                            font-style: normal;

                            line-height: 100%;
                            /* 12px */
                            letter-spacing: 0.24px;
                        }

                        .inneracc {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .forunderline {


                                text-decoration-line: underline;
                            }

                            .leftacc {
                                color: #013159;
                                font-size: 14px;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 120%;
                                letter-spacing: 0.1px;
                            }

                            .rightacc {
                                color: #013159;
                                font-size: 14px;
                                font-style: normal;
                                font-family: "gmedium";
                                line-height: 120%;
                                letter-spacing: 0.1px;
                            }
                        }
                    }

                    .detailbtn {
                        border-radius: 50px;
                        background: #FF0083;
                        display: flex;
                        padding: 16px 13px;
                        justify-content: center;
                        align-items: center;
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        width: 100%;
                        letter-spacing: 0.24px;
                        text-transform: uppercase;
                        border: none;
                    }
                }
            }
        }

        .paginationmain {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 13px 20px 14px 20px;

            .paginationtext {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-family: "gmedium";
                line-height: 120%;
                letter-spacing: 0.1px;
            }

            .pagination {
                background-color: transparent;
                border: none;
                border-radius: 0;

                .active>.page-link,
                .page-link.active {
                    color: #FFF !important;
                    background-color: #013159 !important;
                }

                .page-item {
                    .page-link {
                        display: flex;
                        padding: 6px 11px;
                        align-items: center;
                        border-radius: 5px;
                        background-color: transparent;
                        border: none;
                        color: #8C8C8C;
                        font-size: 15px;
                        font-style: normal;
                        font-family: "gmedium";
                        line-height: 100%;
                        box-shadow: none;
                        outline: none;
                        margin-left: 2px;

                        &:hover {
                            color: #FFF !important;
                            background-color: #013159 !important;
                        }
                    }
                }
            }
        }
    }

    .formobilecretable {
        display: none !important;
    }
}
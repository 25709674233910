.main-banner {
    position: relative;
    padding-top: 144px;
    padding-bottom: 251px;
    background: #F3F3F3;
    backdrop-filter: blur(7px);
 
    .mainboxparent {
        display: flex;
        align-items: center;
        justify-content: center;
        .mainbox {
            border-radius: 10px;
            background: #F9F9F9;
            max-width: 596px;
            width: 100%;
            padding: 25px;

            h3 {
                color: #013159;
                font-family: 'gsemibold';
                font-size: 20px;
                font-style: normal;
                line-height: 120%;
                text-align: center;
                margin-bottom: 30px;
            }

            .twicebox {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
                .innerbox {
                    border-radius: 20px;
                    border: 1px solid #E0E5E9;
                    background: rgba(255, 255, 255, 0.03);
                    padding: 40px 10px;
                    width: 100%;

                    &:hover {
                        border: 1.5px solid #013159;
                    }

                    .parenttext {
                        display: flex;
                        align-items: center;
                        justify-content: center;
flex-direction: column;
                        .text {
                            color: #013159;
                            text-align: center;
                            font-family: 'gmedium';
                            font-size: 20px;
                            font-style: normal;
                            margin-top: 13px;
                            line-height: normal;
                        }

                        .mainimage {
                            width: 56.71px;
                            height: 56.71px;
                            object-fit: cover;
                            object-position: center;
                            overflow: hidden;

                            .metamask {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
            }
        }
    }

}

@media(max-width:600px){
    .main-banner .mainboxparent .mainbox .twicebox{
        flex-direction: column;
    }
    .main-banner .mainboxparent .mainbox{
        padding: 25px 20px !important;
    }
  
}

